import styled, { css } from 'styled-components';

export const Layout = styled.div<LayoutProps>`
  display: grid;
  grid-gap: 24px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 24px;
  grid-template-columns: repeat(8, 1fr);

  ${({ isContentBlock }) => isContentBlock && css`
    padding: 0;
    grid-template-columns: unset;
  `};
`;

export const CardWrapper = styled.div<LayoutProps>`
  grid-column: 3/9;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;

  ${({ isContentBlock }) => isContentBlock && css`
    grid-column: unset;
  `};
`;

type LayoutProps = {
  isContentBlock?: boolean;
};
