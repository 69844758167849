import styled from 'styled-components';
import { media } from 'styles/utils';

import { Heading, Text } from 'common/typography';
import { Button } from 'common/interaction';

export const LabelContainer = styled.div`
  padding: 4px;
  background-color: ${({ theme }) => theme.colors.orange};
  border-radius: 0px 0px 4px 4px;
  position: absolute;
  top: 0;
  right: 24px;

  ${media.desktop`
    padding: 8px;
  `}
`;

export const CardContainer = styled.div<CardContainerProps>`
  background-color: ${({ theme }) => theme.colors.gray.light};
  padding: 16px;
  padding: ${({ hasLabel }) => hasLabel ? '24px 16px 16px' : '16px'};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  position: relative;

  ${media.desktop`
    height: 350px;
    justify-content: center;
  `}
`;

type CardContainerProps = {
  hasLabel?: boolean;
};

export const ValidToText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray.medium};

  ${media.desktop`
    font-size: 12px;
  `}
`;

export const PerClassText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: 14px;
  line-height: 20px;
`;

export const PriceText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray.dark};
  line-height: 20px;

  ${media.desktop`
    font-size: 14px;
    margin-bottom: 15px;
    color: ${({ theme }) => theme.colors.gray.medium};
  `}
`;

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: ${({ margin }) => margin || '0'};

  ${media.desktop<RowProps>`
    flex-direction: column;
    gap: ${({ gap }) => gap || '0'};
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;

  ${media.desktop`
    text-align: center;
  `}
`;

export const ExtendedHeading = styled(Heading)`
  font-weight: bold;
  letter-spacing: 0;
  font-size: 16px;
  letter-spacing: 2px;
`;

export const PriceHeading = styled(Heading)`
  font-size: 20px;
  letter-spacing: 0;
  text-align: center;

  ${media.desktop`
    font-size: 30px;
    line-height: 40px;
  `}
`;

export const ExtendedButton = styled(Button)`
  ${media.desktop`
    max-width: 355px;
    height: 48px;
    padding: 0;
    width: 75%;
    align-self: center;
  `}
`;

type RowProps = {
  margin?: string;
  gap?: string;
}
