import * as React from 'react';

import { useSelector } from 'services/hooks';
import { membershipDataSelector } from 'selectors';
import { MembershipCard, MembershipsBlockHeader } from 'modules/PricingPage';

import { Layout, CardWrapper } from './styled';

export const MembershipsBlock: React.FC<MembershipsBlockProps> = ({ isContentBlock }) => {
  const memberships = useSelector(membershipDataSelector);

  if (memberships.length === 0) return null;

  return (
    <Layout {...{ isContentBlock }}>
      {!isContentBlock && (
        <MembershipsBlockHeader />
      )}
      <CardWrapper {...{ isContentBlock }}>
        {memberships?.map((el) => (
          <MembershipCard key={el.id} item={el} />
        ))}
      </CardWrapper>
    </Layout>
  );
};

type MembershipsBlockProps = {
  isContentBlock?: boolean;
};
