import styled from 'styled-components';

import { Text } from 'common/typography';
import { media } from 'styles/utils';

export const PricingColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 38px;
  gap: 18px;
  flex: 1;

  > form {
    margin: 16px 0 16px;
  }
`;

export const PricingRow = styled.div<PricingRowProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${({ $gap }) => $gap || '0px'};

  & > :first-child {
    flex: 1;
  }
`;

type PricingRowProps = {
  $gap?: string;
};

export const LineDiv = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
`;

export const ExtendedText = styled(Text)<ExtendedTextProps>`
  font-weight: ${({ isBold }) => isBold ? 700 : 400};
`;

type ExtendedTextProps = {
  isBold?: boolean;
};

export const CheckoutFooter = styled.div`
  padding-bottom: 25px;

  ${media.desktop`
    padding-bottom: 64px;
  `}
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
