import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Text } from 'common/typography';
import ArrowLeft from 'vectors/arrowLeft.svg';
import Close from 'vectors/Close.svg';
import { media } from 'styles/utils';

export const CheckoutContainer = styled(motion.div)`
  height: 80vh;
  border-radius: 24px 24px 0px 0px;
  background-color: ${({ theme }) => theme.colors.gray.light};
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  ${media.tablet`
    height: 100vh;
    width: 50vw;
    border-radius: 0px;
    padding: 0 16px;
  `}

  ${media.desktop`
    width: 40vw;
    padding: 0 60px;
  `}
`;

export const CheckoutHeader = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 36px 27px;
  color: ${({ theme }) => theme.colors.black};

  ${media.desktop`
    padding: 55px 27px;
  `}
`;

export const CheckoutHeading = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 12px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  flex: 1;
`;

export const CheckoutFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledArrow = styled(ArrowLeft)`
  &:hover {
    cursor: pointer;
  }
`;

export const CloseIcon = styled(Close)`
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    cursor: pointer;
  }
`;
