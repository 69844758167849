import * as i from 'types';
import * as React from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { useCheckoutContext } from 'modules/PricingPage';
import { Text, Tag } from 'common/typography';
import { getExpiryLabel } from 'services';
import { useAuthenticationUser } from 'services/hooks';

import {
  FirstTimerContainer,
  ContainerBackgroundImage,
  ContentContainer,
  TitleContainer,
  ButtonContainer,
  ExtendedButton,
  HeaderContainer,
  ExtendedHeading,
} from './styled';

export const PromotionClass: React.FC<PromotionClassProps> = ({
  item, tagLabel, backgroundImg,
}) => {
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const expiryLabel = getExpiryLabel(item.expirationInterval, item.expiresIn);
  const { handleOpenModal } = useCheckoutContext();
  const { authenticated } = useAuthenticationUser();

  const handleModal = (item: i.Serie) => {
    if (!authenticated) {
      return navigate('/register',
        {
          state: {
            redirectUrl: '/pricing',
          },
        });
    }

    handleOpenModal(item);
  };

  return (
    <FirstTimerContainer>
      <ContainerBackgroundImage src={backgroundImg} alt="first timer" />
      <ContentContainer>
        <HeaderContainer>
          {tagLabel && (
            <Tag item={tagLabel} />
          )}
        </HeaderContainer>
        <TitleContainer>
          <ExtendedHeading margin="0" upperCase>{item?.name}</ExtendedHeading>
          <Text variant="white" $lineHeight="22">{t('valid for')} {expiryLabel}</Text>
        </TitleContainer>
        <ButtonContainer>
          <ExtendedButton
            size="fullWidth"
            onClick={() => handleModal(item)}
          >
            {t('buy now')}
          </ExtendedButton>
        </ButtonContainer>
      </ContentContainer>
    </FirstTimerContainer>
  );
};

type PromotionClassProps = {
  item: i.Serie;
  tagLabel?: string;
  backgroundImg?: string;
};
