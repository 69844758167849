import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './swiperStyle.css';

import { useSelector } from 'services/hooks';
import { membershipDataSelector } from 'selectors';
import { MembershipCard, MembershipsBlockHeader } from 'modules/PricingPage';

import { Layout, ProgressbarContainer, Progressbar } from './styled';

export const MembershipsBlockMobile: React.FC<MembershipsBlockMobileProps> = ({ isContentBlock }) => {
  const memberships = useSelector(membershipDataSelector);
  const [barNumber, setActiveBar] = React.useState(0);

  return (
    <Layout isContentBlock={isContentBlock}>
      {!isContentBlock && (
        <MembershipsBlockHeader isMobile />
      )}
      <Swiper
        slidesPerView={1.15}
        spaceBetween={12}
        onActiveIndexChange={({ activeIndex }) => {
          setActiveBar(activeIndex);
        }}
      >
        {memberships?.map((el) => (
          <SwiperSlide key={el.id}>
            <MembershipCard item={el} />
          </SwiperSlide>
        ))}
      </Swiper>
      <ProgressbarContainer>
        {memberships?.map((el, index) => (
          <Progressbar
            isActive={index === barNumber}
            key={el.id}
          />
        ))}
      </ProgressbarContainer>
    </Layout>
  );
};

type MembershipsBlockMobileProps = {
  isContentBlock?: boolean;
};
