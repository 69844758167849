import * as i from 'types';
import * as React from 'react';

export const CheckoutContext = React.createContext<CheckoutContextProps>(
  {} as CheckoutContextProps,
);

export const useCheckoutContext = () => {
  const context = React.useContext(CheckoutContext);
  if (!context) throw new Error ('CheckoutContext cannot be rendered outside of the provider');

  return context;
};

export const CheckoutProvider = ({ children }: any) => {
  const [activeItem, setActiveItem] = React.useState<i.Serie | undefined>(undefined);
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const [currentStep, setCurrentStep] = React.useState(1);

  const handleOpenModal = (item?: i.Serie) => {
    setModalOpen(true);
    setActiveItem(item);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setActiveItem(undefined);
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const methods = {
    activeItem,
    isModalOpen,
    handleOpenModal,
    handleCloseModal,
    currentStep,
    setCurrentStep,
    handleNextStep,
    handlePreviousStep,
  };

  return (
    <CheckoutContext.Provider value={methods}>
      {children}
    </CheckoutContext.Provider>
  );
};

type CheckoutContextProps = {
  activeItem: i.Serie;
  isModalOpen: boolean;
  handleCloseModal: () => void;
  handleOpenModal: (item?: i.Serie) => void;
  currentStep: 1 | 2;
  setCurrentStep: (currentStep?: 1 | 2) => void;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}
