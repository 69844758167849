import * as i from 'types';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { editMe } from 'ducks/me';
import {
  generatePaymentUrl, isBrowser, validation, triggerGtagEvent,
  memberShipOptions, classOptions,
} from 'services';
import { useDispatch, useSelector } from 'services/hooks';
import { editMeLoading, meDataSelector } from 'selectors/me';
import { orderDataSelector, orderPaymentInfoSelector } from 'selectors/orders';
import { useCheckoutContext } from 'modules/PricingPage';
import { StepButtonGroup } from 'modules/StepButtonGroup';
import { Input, InputRadio } from 'common/form';
import { Button } from 'common/interaction';
import { Text } from 'common/typography';

import {
  AddressContainer, StyledForm, CheckoutFooter, ExtendedText,
  PaymentContainer, FormContainer,
} from './styled';

export const CheckoutForm = () => {
  const { language } = useI18next();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(editMeLoading);
  const orderData = useSelector(orderDataSelector);
  const userData = useSelector(meDataSelector);
  const paymentInfo = useSelector(orderPaymentInfoSelector);
  const { currentStep, activeItem } = useCheckoutContext();
  const smallDescription = activeItem?.description?.substring(
    activeItem?.description?.indexOf('[') + 1,
    activeItem?.description?.lastIndexOf(']'),
  );

  const defaultValues = {
    address: userData?.address || '',
    zip: userData?.zip || '',
    city: userData?.city || '',
    payment_method: activeItem?.unlimited ? 'sepa' : 'ideal',
  };

  const {
    register, control, handleSubmit, watch, formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const handleCheckout = (data: i.User & { payment_method: i.PaymentMethods }) => {
    const { payment_method, ...user } = data;

    dispatch(editMe(user))
      .then(() => {
        if (!orderData || !paymentInfo) return;
        const payment_url = generatePaymentUrl({
          order: orderData,
          paymentInfo,
          paymentMethod: payment_method,
          language,
        });
        triggerGtagEvent('begin_checkout', {
          user,
          serie: activeItem,
          payment_method,
        });

        if (payment_url && isBrowser()) {
          window.location.href = payment_url;
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <StyledForm onSubmit={handleSubmit(handleCheckout)}>
      <FormContainer>
        {activeItem?.unlimited && (
          <>
            <Input
              label={t('address')}
              defaultValue={defaultValues?.address}
              error={errors.address}
              {...register('address',
                { ...validation.required })}
            />
            <AddressContainer>
              <Input
                label={t('zipcode')}
                defaultValue={defaultValues?.zip}
                error={errors.zip}
                {...register('zip',
                  { ...validation.required })}
              />
              <Input
                label={t('city')}
                defaultValue={defaultValues?.city}
                error={errors.city}
                {...register('city',
                  { ...validation.required })}
              />
            </AddressContainer>
          </>
        )}
        <PaymentContainer>
          <ExtendedText>{t('payment method')}</ExtendedText>
          <Controller
            name="payment_method"
            control={control}
            render={(({ field: { onChange, value } }) => (
              <InputRadio
                {...{ value, onChange }}
                direction="horizontal"
                items={activeItem?.unlimited ? memberShipOptions : classOptions}
              />
            ))}
          />
          {watch('payment_method') === 'sepa' && activeItem?.unlimited && (
            <>
              <Text>{t('sepa explanation')}</Text>
              <Text>{smallDescription}</Text>
            </>
          )}
        </PaymentContainer>
      </FormContainer>
      <CheckoutFooter>
        <StepButtonGroup {...{ currentStep }} />
        <Button
          type="submit"
          size="fullWidth"
          disabled={!isValid}
          $isValid={isValid}
          isLoading={isLoading}
        >
          {t('buy now')}
        </Button>
      </CheckoutFooter>
    </StyledForm>
  );
};
