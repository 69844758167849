import styled from 'styled-components';
import { Text } from 'common/typography';

import { media } from 'styles/utils';

export const AddressContainer = styled.div`
  display: grid;
  grid-template-columns: 33% auto;
  grid-gap: 12px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  ${media.desktop`
    padding-top: 57px;
  `}
`;

export const CheckoutFooter = styled.div`
  padding-bottom: 25px;

  ${media.desktop`
    padding-bottom: 64px;
  `}
`;

export const ExtendedText = styled(Text)`
  font-weight: 700;
  margin-bottom: 8px;
`;

export const PaymentContainer = styled.div`

`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
