import * as i from 'types';
import * as React from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { useCheckoutContext } from 'modules/PricingPage';
import { Text } from 'common/typography';
import { formatPrice, getExpiryLabel, triggerGtagEvent } from 'services';
import { useDevice, useAuthenticationUser } from 'services/hooks';

import {
  CardContainer, PriceHeading, Column, Row, ExtendedButton,
  PriceText, ExtendedHeading, ValidToText, PerClassText, LabelContainer,
} from './styled';

export const ClassCard: React.FC<ClassCardProps> = ({ item }) => {
  const { isMobile } = useDevice();
  const { navigate } = useI18next();
  const { t } = useTranslation();
  const { handleOpenModal } = useCheckoutContext();
  const { authenticated } = useAuthenticationUser();
  const price = item.groupPrice?.amount || item.price.amount;
  const pricePerClass =  formatPrice(price / item.classCount, { hideZeroDecimals: true });
  const expiryLabel = getExpiryLabel(item.expirationInterval, item.expiresIn);
  const label = item.name.substring(item.name.indexOf('[') + 1, item.name.lastIndexOf(']'));
  const hasLabel = Boolean(label);
  const name = item.name.split('[')[0];

  const handleModal = (item: i.Serie) => {
    if (!authenticated) {
      return navigate('/register',
        {
          state: {
            redirectUrl: '/pricing',
          },
        });
    }

    triggerGtagEvent('add_to_cart', item);
    handleOpenModal(item);
  };

  return (
    <CardContainer {...{ hasLabel }}>
      {hasLabel && (
        <LabelContainer>
          <Text
            $uppercase
            $size={12}
          >
            {label}
          </Text>
        </LabelContainer>
      )}
      <Row gap="14px">
        <ExtendedHeading
          upperCase
          variant="black"
          margin="0"
          as="h2"
        >
          {name}
        </ExtendedHeading>
        <Row margin="0 18px">
          <PriceHeading
            variant="black"
            as="h3"
            margin="0 8px"
          >
            {pricePerClass}
          </PriceHeading>
          <PerClassText>{t('per class')}</PerClassText>
        </Row>
      </Row>
      <Row gap="16px">
        <Column>
          <PriceText>{formatPrice(price)} {t('in total')}</PriceText>
          {isMobile && (
            <ValidToText>{t('valid for')} {expiryLabel}</ValidToText>
          )}
        </Column>
        <ExtendedButton
          variant="CTA"
          onClick={() => handleModal(item)}
        >
          {t('buy now')}
        </ExtendedButton>
        {!isMobile && (
          <ValidToText>{t('valid for')} {expiryLabel}</ValidToText>
        )}
      </Row>
    </CardContainer>
  );
};

type ClassCardProps = {
  item: i.Serie;
}
