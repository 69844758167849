import styled, { css } from 'styled-components';

export const HeaderContainer = styled.div<HeaderContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-column: 1/3;

  ${({ isMobile }) => isMobile && css`
    margin-bottom: 16px;
  `};
`;

type HeaderContainerProps = {
  isMobile?: boolean;
};
