import styled, { css } from 'styled-components';

export const Layout = styled.div<LayoutProps>`
  padding: ${({ isContentBlock }) => isContentBlock ? 0 : 24}px;
`;

type LayoutProps = {
  isContentBlock?: boolean;
};

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

export const Progressbar = styled.div<ProgressBarProps>`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray.light};
  transition: .2s all;

  ${({ isActive }) => isActive && css`
    background-color: ${({ theme }) => theme.colors.gray.dark};
  `}
`;

type ProgressBarProps = {
  isActive?: boolean;
};

export const ProgressbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
