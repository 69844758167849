import * as i from 'types';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { useSelector, useDispatch } from 'services/hooks';
import { formatPrice } from 'services';
import { applyOrderDiscount, applyOrderGiftcard } from 'ducks/orders';
import { Text } from 'common/typography';
import { useCheckoutContext } from 'modules/PricingPage';
import { StepButtonGroup } from 'modules/StepButtonGroup';
import { orderCreateLoading } from 'selectors';
import { Button } from 'common/interaction';
import { Input } from 'common/form';

import { PricingColumn, PricingRow, LineDiv, ExtendedText, CheckoutFooter, PageWrapper } from './styled';

export const PricingTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentStep, handleNextStep, activeItem } = useCheckoutContext();
  const loading = useSelector(orderCreateLoading);
  const order = useSelector((state) => state.orders.data);
  const [isPromotion, setIsPromotion] = React.useState(false);

  const isSubscription = activeItem?.unlimited;
  const orderItem = order?.seriesOrderItems?.[0];
  const totalPrice = order?.amountDue?.amount || 0;
  const pricePerClass = formatPrice(totalPrice / activeItem?.classCount, { hideZeroDecimals: true });
  const groupDiscount = (activeItem?.price.amount || 0) - (activeItem?.groupPrice?.amount || 0);
  const discount = orderItem?.discount?.amount;
  const giftcard = order?.transactions?.[0];

  const { register, handleSubmit, reset, setError,
    formState: {
      errors,
    },
  } = useForm({
    mode: 'onChange',
  });

  const submitPromotions = (data: i.PromotionData) => {
    if (data.promotion && order) {
      dispatch(applyOrderDiscount(order?.id, data.promotion))
        .then(() => {
          reset();
          setIsPromotion(false);
        })
        .catch(() => {
          setError('promotion', {
            message: 'Invalid promotion code',
          });
        });
    }

    if (data.giftcard && data.giftcard_pin && order) {
      dispatch(applyOrderGiftcard(order?.id, data.giftcard, data.giftcard_pin))
        .then(() => {
          reset();
          setIsPromotion(false);
        })
        .catch(() => {
          setError('giftcard', {
            message: 'Invalid giftcard code',
          });
        });
    }
  };

  return (
    <PageWrapper>
      <PricingRow>
        {isSubscription ? (
          <Text>{formatPrice(totalPrice)} {t('per 4 weeks')}</Text>
        ) : (
          <Text>{pricePerClass} {t('per class')}</Text>
        )}
      </PricingRow>
      <PricingColumn>
        {(Boolean(discount) || Boolean(groupDiscount)) && (
          <PricingRow>
            <ExtendedText>{activeItem?.name}</ExtendedText>
            <ExtendedText>
              {formatPrice(activeItem?.price.amount || 0)}
            </ExtendedText>
          </PricingRow>
        )}
        {Boolean(activeItem?.groupPrice?.amount) && (
          <PricingRow>
            <ExtendedText>{t('group discount')}</ExtendedText>
            <ExtendedText> - {formatPrice(groupDiscount)}</ExtendedText>
          </PricingRow>
        )}
        {Boolean(giftcard) && (
          <PricingRow>
            <ExtendedText>{t('giftcard')}</ExtendedText>
            <ExtendedText> - {formatPrice(giftcard?.total.amount || 0)}</ExtendedText>
          </PricingRow>
        )}
        {Boolean(discount) && (
          <PricingRow>
            <ExtendedText>{t('promotion code')}</ExtendedText>
            <ExtendedText> - {formatPrice(discount || 0)}</ExtendedText>
          </PricingRow>
        )}
        {!isPromotion ? (
          <PricingRow>
            <Button
              variant="outlined-black"
              size="small"
              onClick={() => setIsPromotion(true)}
            >
              {t('add giftcard/promotion')}
            </Button>
          </PricingRow>
        ) : (
          <form onSubmit={handleSubmit(submitPromotions)}>
            <Input
              error={errors.promotion}
              label="Promotion code"
              {...register('promotion')}
            />
            <PricingRow $gap="16px">
              <Input label="Giftcard" {...register('giftcard')} error={errors.giftcard} />
              <Input label="Giftcard Pin" {...register('giftcard_pin')} error={errors.giftcard} />
            </PricingRow>
            <PricingRow $gap="16px">
              <Button size="auto">{t('add')}</Button>
              <Button
                size="auto"
                variant="secondary"
                onClick={() => setIsPromotion(false)}
              >
                cancel
              </Button>
            </PricingRow>
          </form>
        )}
        <LineDiv />
        <PricingRow>
          <ExtendedText isBold>{t('total')}</ExtendedText>
          <ExtendedText isBold>{formatPrice(totalPrice)}</ExtendedText>
        </PricingRow>
      </PricingColumn>
      <CheckoutFooter>
        <StepButtonGroup {...{ currentStep }} />
        <Button
          type="submit"
          size="fullWidth"
          isLoading={loading}
          onClick={handleNextStep}
        >
          {t('next')}
        </Button>
      </CheckoutFooter>
    </PageWrapper>
  );
};
