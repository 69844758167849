import * as i from 'types';
import * as React from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { CheckMark } from 'common/svg';
import { useCheckoutContext } from 'modules/PricingPage';
import { formatPrice, triggerGtagEvent } from 'services';
import { useAuthenticationUser } from 'services/hooks';

import {
  CardContainer, ExtendedHeading, PriceHeading, Column, PerMembershipText, ValidToText,
  BenefitContainer, BenefitIconContainer, ExtendedButton, CommitmentText,
} from './styled';

export const MembershipCard: React.FC<MembershipCardProps> = ({ item }) => {
  const { handleOpenModal } = useCheckoutContext();
  const { navigate } = useI18next();
  const { t } = useTranslation();
  const { authenticated } = useAuthenticationUser();
  const price = formatPrice(item.groupPrice?.amount || item.price.amount);
  const smallDescription = item?.description.substring(
    item?.description.indexOf('[') + 1,
    item?.description.lastIndexOf(']'),
  );
  const benefits = item?.description.replace(`[${smallDescription}]`, '')
    .split('<br/>')
    .join('')
    .split('*');

  const handleModal = (item: i.Serie) => {
    if (!authenticated) {
      return navigate('/register',
        {
          state: {
            redirectUrl: '/pricing',
          },
        });
    }

    triggerGtagEvent('add_to_cart', item);
    handleOpenModal(item);
  };

  return (
    <CardContainer>
      <Column gap="14px">
        <ExtendedHeading
          upperCase
          variant="black"
          margin="0"
          as="h2"
        >
          {item?.name}
        </ExtendedHeading>
        <Column>
          <PriceHeading
            variant="black"
            as="h3"
            margin="0 8px"
          >
            {price}
          </PriceHeading>
          <CommitmentText>
            {t('per 4 weeks')}
          </CommitmentText>
        </Column>
      </Column>
      <Column>
        {benefits.map((benefit) => (
          <BenefitContainer key={benefit}>
            <BenefitIconContainer>
              <CheckMark />
            </BenefitIconContainer>
            <PerMembershipText>{benefit}</PerMembershipText>
          </BenefitContainer>
        ))}
      </Column>
      <ExtendedButton
        variant="CTA"
        onClick={() => handleModal(item)}
      >
        {t('buy now')}
      </ExtendedButton>
      <ValidToText>{smallDescription}</ValidToText>
    </CardContainer>
  );
};

type MembershipCardProps = {
  item: i.Serie;
};
