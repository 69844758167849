import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import PricingHeaderImage from 'images/document-pageheader.jpg';
import { HalfHeader } from 'common/layout';

export const PricingHeader = () => {
  const { t } = useTranslation();

  return (
    <HalfHeader
      subtitle={t('our pricing of')}
      imageUrl={PricingHeaderImage}
    />
  );
};
