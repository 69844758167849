import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Heading, Text } from 'common/typography';
import { classesDataSelector, starterClassesDataSelector } from 'selectors/classes';
import { userClassesData } from 'selectors/me';
import { getUserClasses } from 'ducks/me';
import { ClassCard, PromotionClass } from 'modules/PricingPage';
import { useSelector, useAuthenticationUser, useDispatch } from 'services/hooks';
import FirstTimerBanner from 'images/firstTimerBanner.png';
import PersonalTrainingBanner from 'images/personalTraining.jpeg';

import { Layout, HeaderContainer, CardWrapper, PromoWrapper } from './styled';

export const ClassesBlock: React.FC<ClassesBlockProps> = ({ isContentBlock }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { determined, authenticated } = useAuthenticationUser();
  const classesData = useSelector(classesDataSelector);
  const promotionClass = useSelector((state) => state.series.data?.promotions?.[0]);
  const starterClasses = useSelector(starterClassesDataSelector);
  const userBookedClasses = useSelector(userClassesData) || [];

  React.useEffect(() => {
    if (determined && authenticated) {
      dispatch(getUserClasses());
    }
  }, [determined]);

  return (
    <Layout isContentBlock={isContentBlock}>
      {!isContentBlock && (
        <HeaderContainer>
          <Heading as="h2" variant="black" margin="0" upperCase>{t('classes')}</Heading>
          <Text variant="gray" $lineHeight="24">{t('buy single classes which are valid within a certain time period')}</Text>
        </HeaderContainer>
      )}
      {starterClasses && userBookedClasses.length === 0 && (
        <PromoWrapper>
          <PromotionClass
            item={starterClasses}
            tagLabel="First-timers only"
            backgroundImg={FirstTimerBanner}
          />
        </PromoWrapper>
      )}
      <CardWrapper>
        {classesData?.map((el, index) => (
          <ClassCard
            item={el}
            key={index}
          />
        ))}
      </CardWrapper>
      {promotionClass && (
        <PromoWrapper>
          <PromotionClass
            item={promotionClass}
            backgroundImg={PersonalTrainingBanner}
          />
        </PromoWrapper>
      )}
    </Layout>
  );
};

type ClassesBlockProps = {
  isContentBlock?: boolean;
};
